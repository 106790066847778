const c = [
	() => import("../../../src/routes/__layout.svelte"),
	() => import("../components/error.svelte"),
	() => import("../../../src/routes/index.svelte"),
	() => import("../../../src/routes/scrollbar/__layout.reset.svelte"),
	() => import("../../../src/routes/scrollbar/index.svelte"),
	() => import("../../../src/routes/contact.svelte"),
	() => import("../../../src/routes/about.svelte"),
	() => import("../../../src/routes/music/index.svelte"),
	() => import("../../../src/routes/music/sveltesummit2021.svelte"),
	() => import("../../../src/routes/music/svelte.svelte"),
	() => import("../../../src/routes/code/__layout.svelte"),
	() => import("../../../src/routes/code/index.svelte"),
	() => import("../../../src/routes/code/experiments/index.svelte"),
	() => import("../../../src/routes/code/experiments/life.svelte"),
	() => import("../../../src/routes/code/sketches/index.svelte"),
	() => import("../../../src/routes/code/websites.svelte"),
	() => import("../../../src/routes/art/index.svelte"),
	() => import("../../../src/routes/uh.svelte")
];

const d = decodeURIComponent;

export const routes = [
	// src/routes/index.svelte
	[/^\/$/, [c[0], c[2]], [c[1]]],

	// src/routes/scrollbar/index.svelte
	[/^\/scrollbar\/?$/, [c[3], c[4]], []],

	// src/routes/contact.svelte
	[/^\/contact\/?$/, [c[0], c[5]], [c[1]]],

	// src/routes/about.svelte
	[/^\/about\/?$/, [c[0], c[6]], [c[1]]],

	// src/routes/music/index.svelte
	[/^\/music\/?$/, [c[0], c[7]], [c[1]]],

	// src/routes/music/sveltesummit2021.svelte
	[/^\/music\/sveltesummit2021\/?$/, [c[0], c[8]], [c[1]]],

	// src/routes/music/svelte.svelte
	[/^\/music\/svelte\/?$/, [c[0], c[9]], [c[1]]],

	// src/routes/code/index.svelte
	[/^\/code\/?$/, [c[0], c[10], c[11]], [c[1]]],

	// src/routes/code/experiments/index.svelte
	[/^\/code\/experiments\/?$/, [c[0], c[10], c[12]], [c[1]]],

	// src/routes/code/experiments/life.svelte
	[/^\/code\/experiments\/life\/?$/, [c[0], c[10], c[13]], [c[1]]],

	// src/routes/code/sketches/index.svelte
	[/^\/code\/sketches\/?$/, [c[0], c[10], c[14]], [c[1]]],

	// src/routes/code/websites.svelte
	[/^\/code\/websites\/?$/, [c[0], c[10], c[15]], [c[1]]],

	// src/routes/art/index.svelte
	[/^\/art\/?$/, [c[0], c[16]], [c[1]]],

	// src/routes/uh.svelte
	[/^\/uh\/?$/, [c[0], c[17]], [c[1]]]
];

// we import the root layout/error components eagerly, so that
// connectivity errors after initialisation don't nuke the app
export const fallback = [c[0](), c[1]()];